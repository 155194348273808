import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Card from "../../../../components/Card/Card";

import Graph from "./WalkOfPrivilegeBarGraph";
// import Summary from "./WopSummary";

const useStyles = makeStyles((theme) => ({
  results: {
    height: "90vh",
    overflow: "auto",
    // marginTop: "1vh",
  },
  container: {
    width: "80vw",
    margin: 0,
    display: "flex",
    justifyContent: "center",
  },
  backBtn: {
    right: "2vw",
    bottom: "1vh",
    position: "absolute",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function WopResults(props) {
  const classes = useStyles();
  const { sessionData, companyData, isCompanyWideData, handleClick } = props;

  return (
    <div className={classes.results}>
      <GridContainer className={classes.container}>
        <GridItem xs={12} md={10}>
          <Card>
            <Graph
              sessionData={sessionData}
              companyData={companyData}
              isCompanyWideData={isCompanyWideData}
              handleClick={handleClick}
            />
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

WopResults.propTypes = {
  sessionData: PropTypes.arrayOf(PropTypes.object),
  companyData: PropTypes.arrayOf(PropTypes.object),
  isCompanyWideData: PropTypes.bool,
  handleClick: PropTypes.func,
};
