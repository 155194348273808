import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Pusher from "pusher-js";
import Cookie from "universal-cookie";

export const AuthContext = React.createContext({});
const cookie = new Cookie();

const Auth = ({ children }) => {
  const [pusher, setPusher] = useState(null);
  const [user, setUser] = useState({ id: 0 });

  useEffect(() => {
    if (!pusher) {
      setPusher(
        new Pusher(`${process.env.REACT_APP_PUSHER_KEY}`, {
          cluster: `${process.env.REACT_APP_PUSHER_CLUSTER}`,
        })
      );
    }
    // checkAuth();
  }, [pusher]);

  useEffect(() => {
    if (user?.id === 0) {
      setUser(cookie.get("user"));
    }
    // localStorage.setItem("feathers-jwt", cookie.get("accessToken"));
    // console.log("all cookies inside useEffect", cookie.getAll());
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        pusher,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

Auth.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array,
  ]),
};

export default Auth;
