import React, { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import WopResults from "../wopResults/WopResults";
import { PulseLoader } from "react-spinners";
import WordCloudResultsScreen from "../WordCloud/WordCloudScreen";
import PollResultsScreen from "../Poll/PollResultScreen";
import ComfortMeterResults from "../ComfortMeter/ComfortMeterResults";
import Cookie from "universal-cookie";

const cookie = new Cookie();

const ReportSlide = ({
  sessionId,
  reportType,
  // wopModuleId,
  // wordCloudModuleId,
  // pollModuleId,
  moduleTitle,
  moduleId,
  isDemo,
  // czModuleId,
}) => {
  const [sessionData, setSessionData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [moduleData, setModuleData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCompanyWideData, setIsCompanyWideData] = useState(false);
  const [localPollModId] = useState(Number(cookie.get("pollModuleId")));
  const [localWopModId] = useState(Number(cookie.get("wopModuleId")));
  const [localWordCloudModId] = useState(
    Number(cookie.get("wordCloudModuleId"))
  );
  const [localCZModId] = useState(Number(cookie.get("czModuleId")));

  const handleClick = () => {
    setIsCompanyWideData(!isCompanyWideData);
  };

  useEffect(() => {
    switch (reportType) {
      case "wop":
        axios({
          method: "get",
          url: `${process.env.REACT_APP_EVENT_SERVICE}/auto-host-reports?sessionId=${sessionId}&moduleId=${localWopModId}&reportType=${reportType}`,
          // headers: {
          //   Authorization: `bearer ${loadAuthToken()}`,
          // },
        }).then((res) => {
          console.log("wop report res", res.data);
          setSessionData(res.data.sessionData);
          setCompanyData(res.data.companyData);
          setModuleData(res.data.wordCloudData);
          setIsLoading(false);
        });
        break;
      case "word-cloud":
        axios({
          method: "get",
          url: `${process.env.REACT_APP_EVENT_SERVICE}/auto-host-reports?sessionId=${sessionId}&moduleId=${localWordCloudModId}&reportType=${reportType}`,
          // headers: {
          //   Authorization: `bearer ${loadAuthToken()}`,
          // },
        }).then((res) => {
          // console.log("word cloud report res", res.data);
          setSessionData(
            res.data.sessionData.sort((a, b) => {
              if (a.question.order < b.question.order) {
                return -1;
              }
              if (b.question.order < a.question.order) {
                return 1;
              }
              // a must be equal to b
              return 0;
            })
          );
          setModuleData(res.data.wordCloudData);
          setIsLoading(false);
        });
        break;
      case "poll":
        axios({
          method: "get",
          url: `${process.env.REACT_APP_EVENT_SERVICE}/auto-host-reports?sessionId=${sessionId}&moduleId=${localPollModId}&reportType=${reportType}`,
          // headers: {
          //   Authorization: `bearer ${loadAuthToken()}`,
          // },
        }).then((res) => {
          // console.log("poll report res", res);
          setSessionData(res.data.sessionData);
          setIsLoading(false);
        });

        break;
      case "comfort-zone":
        axios({
          method: "get",
          url: `${process.env.REACT_APP_EVENT_SERVICE}/auto-host-reports?sessionId=${sessionId}&moduleId=${localCZModId}&reportType=${reportType}`,
          // headers: {
          //   Authorization: `bearer ${loadAuthToken()}`,
          // },
        }).then((res) => {
          // console.log("poll report res", res);
          setSessionData(res.data.sessionData);
          setIsLoading(false);
        });

        break;

      default:
        console.log(`${reportType} invalid`);
    }
  }, [
    reportType,
    sessionId,
    localWopModId,
    localPollModId,
    localWordCloudModId,
    localCZModId,
  ]);

  switch (reportType) {
    case "wop": {
      return isLoading ? (
        <PulseLoader
          sizeUnit="px"
          size={15}
          color="#60A681"
          loading
          speedMultiplier={".5"}
        />
      ) : (
        <>
          <WopResults
            sessionData={sessionData}
            companyData={companyData}
            isCompanyWideData={isCompanyWideData}
            handleClick={handleClick}
          />
        </>
      );
    }
    case "word-cloud": {
      return isLoading ? (
        <PulseLoader sizeUnit="px" size={15} color="#60A681" loading />
      ) : (
        <>
          <WordCloudResultsScreen
            moduleTitle={moduleTitle}
            sessionId={sessionId}
            moduleId={moduleId}
            sessionData={sessionData}
            isDemo={isDemo}
          />
        </>
      );
    }
    case "poll": {
      return isLoading ? (
        <PulseLoader sizeUnit="px" size={15} color="#60A681" loading />
      ) : (
        <>
          <PollResultsScreen
            moduleTitle={moduleTitle}
            sessionId={sessionId}
            moduleId={moduleId}
            moduleData={moduleData}
            sessionData={sessionData}
          />
        </>
      );
    }
    case "comfort-zone": {
      return isLoading ? (
        <PulseLoader sizeUnit="px" size={15} color="#60A681" loading />
      ) : (
        <>
          <ComfortMeterResults
            moduleTitle={moduleTitle}
            sessionId={sessionId}
            moduleId={moduleId}
            moduleData={moduleData}
            sessionData={sessionData}
          />
        </>
      );
    }
    default: {
      return <div>default</div>;
    }
  }
};

ReportSlide.propTypes = {
  sessionId: PropTypes.number.isRequired,
  reportType: PropTypes.string,
  moduleTitle: PropTypes.string,
  moduleId: PropTypes.number.isRequired,
  isDemo: PropTypes.bool,
};
export default ReportSlide;
