import React from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import { Button } from "@material-ui/core";
import { demoData } from "./chartData";
// import { PulseLoader } from "react-spinners";

const CurrentData = (props) => {
  let chartData = [];
  const { isCompanyWideData, sessionData, companyData } = props;
  if (sessionData.length && !isCompanyWideData) {
    chartData = sessionData.map((answer) => answer.points);
  } else if (isCompanyWideData) {
    chartData = companyData.map((answer) => answer.points);
  } else {
    chartData = demoData;
  }
  return {
    labels: chartData.sort((a, b) => b - a),
    datasets: [
      {
        label: "Privilege Distribution",
        backgroundColor: "#6CCA98",
        // borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: chartData.sort((a, b) => b - a),
      },
    ],
  };
};

const options = {
  responsive: true,
  tooltips: {
    mode: "label",
  },
  plugins: {
    labels: false,
  },
  legend: {
    display: false,
    labels: {
      fontSize: 25,
      fontFamily: '"Montserrat","Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          suggestedMin: -10,
          suggestedMax: 10,
          stepSize: 5,
          fontSize: 25,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontSize: 25,
        },
      },
    ],
  },
};

const setTitle = (props) => {
  const { isCompanyWideData, isAllTime } = props;
  if (isCompanyWideData) {
    return <h3>Company-wide Privilege Distribution</h3>;
  }
  if (isAllTime) {
    return <h3>All-time Privilege Distribution</h3>;
  }
  return <h3>Privilege Distribution</h3>;
};

const WalkOfPrivilegeBarGraph = (props) => {
  const { isCompanyWideData, handleClick } = props;

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {setTitle(props)}
      </div>
      <Bar
        data={CurrentData(props)}
        width={1000}
        height={450}
        options={options}
      />
      <Button onClick={handleClick}>
        {" "}
        {isCompanyWideData
          ? `Click To See Session Data`
          : `Click To See Company Data`}{" "}
      </Button>
    </>
  );
};

WalkOfPrivilegeBarGraph.propTypes = {
  sessionData: PropTypes.arrayOf(PropTypes.object),
  companyData: PropTypes.arrayOf(PropTypes.object),
  isCompanyWideData: PropTypes.bool,
};

export default WalkOfPrivilegeBarGraph;
