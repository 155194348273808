import React from "react";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../../assests/img/translator-color-stacked.png";

const useStyles = makeStyles({
  outerDiv: {
    margin: "auto",
    textAlign: "center",
  },
  imageDiv: {
    paddingTop: "1rem",
    paddingBottom: "1.5rem",
  },
  contentDiv: {
    paddingTop: "1.5rem",
  },
  text: {
    fontSize: "1.4rem",
  },
  largeText: {
    fontSize: "1.6rem",
  },
});

const Default = () => {
  const classes = useStyles();

  return (
    <div className={classes.outerDiv}>
      <div className={classes.imageDiv}>
        <img height="100px" src={Logo} alt="Logo" />
      </div>
      <Divider />
      <div className={classes.contentDiv}>
        <p className={classes.largeText}>
          <strong>What is Translator?</strong>
        </p>
        <br />
        <p className={classes.text}>
          Translator is a totally secure, 100% anonymous, 3rd party
        </p>
        <p className={classes.text}>
          web app designed to help you get the most out of this
        </p>
        <p className={classes.text}>training experience.</p>
        <br />
        <p className={classes.text}>
          So relax, take a deep breath, and get ready to learn.
        </p>
        <p className={classes.text}>Your session will start at any moment!</p>
      </div>
    </div>
  );
};

export default Default;
