import Cookies from "universal-cookie";

const cookies = new Cookies();

export const loadAuthToken = () => cookies.get("accessToken");
export const saveAuthToken = (authToken) => {
  try {
    localStorage.setItem("feathers-jwt", authToken);
  } catch (e) {
    console.log(e);
  }
};
