import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
// import Divider from '@material-ui/core/Divider';
// import Button from '../../../../components/CustomButtons/Button';
import WordPopcornScreen from "./WordPopcorn/WordPopcornScreen";
import axios from "axios";
// import WordPopcornWelcome from './WordPopcornWelcome';

const useStyles = makeStyles({
  outerDiv: {
    margin: "auto",
    textAlign: "center",
  },
  imageDiv: {
    // paddingTop: '1rem',
    paddingBottom: "2rem",
  },
  contentDiv: {
    paddingTop: "2.5rem",
  },
  text: {
    fontSize: "1.4rem",
  },
  largeText: {
    fontSize: "1.6rem",
  },
  messageIcon: {
    color: "#6CCA98",
    height: "100px",
    width: "100px",
  },
  completions: {
    fontSize: "1.4rem",
    marginTop: "50px",
  },
  image: {
    width: "200px",
    marginBottom: "5px",
  },
});

const WordPopcorn = ({
  moduleTitle,
  // wordPopcornQuestion,
  sessionId,
  moduleId,
  currentModuleId,
  // wordPopcornIcon,
  channel,
  currentSlide,
  isDemo,
  // wordPopcornCount,
}) => {
  // const [viewingResults, setViewingResults] = useState(false);
  // const [initialWordCount, setInitialWordCount] = useState(0);
  const [, setCompletedCount] = useState(0);
  const [newWord, setNewWord] = useState("");
  const [popcornData, setPopcornData] = useState({});
  // console.log("Ids", sessionId, moduleId);

  // useEffect(() => {
  //   setCompletedCount(wordPopcornCount);
  // }, []);

  useEffect(() => {
    axios({
      method: "get",
      url: `${process.env.REACT_APP_MODULE_SERVICE}/word-popcorn/${currentSlide.moduleId}`,
    })
      .then((res) => {
        setPopcornData(res.data);
      })
      .catch((err) => {
        console.error(err, "Error");
      });
  }, [currentSlide]);

  useEffect(() => {
    const binds = [];
    if (channel) {
      channel.bind(`popcorn_words_${sessionId}_${currentModuleId}`, (data) => {
        setCompletedCount(data.count);
        setNewWord(data.answer);
      });
      return () => {
        binds.forEach((bind) => {
          channel.unbind(bind);
        });
        channel.unbind(`popcorn_words_${sessionId}_${currentModuleId}`);
      };
    }
  }, [channel, currentModuleId, sessionId]);

  // const startViewingResults = () => {
  //   setViewingResults(true);
  // };

  const classes = useStyles();

  return (
    <div className={classes.outerDiv} style={{ marginTop: "10px" }}>
      <WordPopcornScreen
        moduleTitle={moduleTitle}
        sessionId={sessionId}
        moduleId={currentModuleId}
        popcornData={popcornData}
        // wordPopcornQuestion={wordPopcornQuestion}
        // isDemo={isDemo}
        // setViewingResults={setViewingResults}
        newWord={newWord}
        // wordPopcornIcon={wordPopcornIcon}
      />
    </div>
  );

  // return (
  //   <div className={classes.outerDiv}>
  //     <div className={classes.imageDiv}>
  //       <img src={wordPopcornIcon} className={classes.image} alt="Word Popcorn" />
  //     </div>
  //     <Divider />
  //     <div className={classes.contentDiv}>
  //       <p className={classes.largeText}><strong>{moduleTitle}</strong></p>
  //       <br />
  //       <p className={classes.text}>
  //         Thank you for your participation!
  //       </p>
  //       <div>
  //         <p className={classes.completions}>
  //           <span className={classes.number}>{completedCount}</span>
  //           <br />
  //           Words
  //         </p>
  //       </div>

  //       <Button
  //         width="100%"
  //         onClick={() => startViewingResults()}
  //         align="flex-end"
  //         style={{ right: '5vw', bottom: '7vh', position: 'absolute' }}
  //       >
  //         Results
  //       </Button>
  //     </div>
  //   </div>
  // );
};

WordPopcorn.propTypes = {
  // moduleTitle: PropTypes.string.isRequired,
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  // wordPopcornQuestion: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  channel: PropTypes.object.isRequired,
  wordPopcornIcon: PropTypes.string,
  isDemo: PropTypes.bool,
  currentModuleId: PropTypes.number.isRequired,
  // wordPopcornCount: PropTypes.number.isRequired,
};

export default WordPopcorn;
