import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
import Divider from "@material-ui/core/Divider";
import Feet from "../../../assests/img/feetcolors.png";
import { AuthContext } from "../../../Auth";
// import Results from "./wop/WopResults";
// import { loadAuthToken } from "../../../utils/auth";

const useStyles = makeStyles({
  outerDiv: {
    margin: "auto",
    textAlign: "center",
  },
  image: {
    width: "120px",
    marginBottom: "20px",
  },
  contentDiv: {
    paddingTop: "1.4rem",
  },
  text: {
    fontSize: "1.4rem",
  },
  largeText: {
    fontSize: "1.6rem",
  },
});

const WalkOfPrivilege = (props) => {
  const [questionPercents, setQuestionPercents] = useState([]);
  const [completed, setCompleted] = useState(0);
  // const [isAllTime, setIsAllTime] = useState(false);

  const classes = useStyles();
  const { moduleTitle, moduleId, sessionId, channel, currentModuleId } = props;
  const context = useContext(AuthContext);
  const setQuestionsWithPercents = (questions = questionPercents) => {
    const rawQuestionsCopy = props.walkOfPrivilegeQuestions;
    const rawPercentsCopy = questions;
    if (rawQuestionsCopy) {
      rawPercentsCopy.forEach((questionObj) => {
        const index = rawQuestionsCopy.findIndex(
          (el) => el.id === questionObj.id
        );
        rawQuestionsCopy[index] = {
          ...rawQuestionsCopy[index],
          percent: questionObj.percent,
        };
      });
    }
  };
  const getFinishedUsers = async (displayType = "basic") => {
    await axios({
      method: "get",
      url: `${process.env.REACT_APP_EVENT_SERVICE}/wop-answered?sessionId=${sessionId}&moduleId=${currentModuleId}`,
      //   headers: {
      //     Authorization: `bearer ${loadAuthToken()}`,
      //   },
    })
      .then(async (res) => {
        if (
          res.data === "your token is not valid" ||
          res.data === "Your token is expired"
        ) {
          context.sendToLogin();
        }
        setCompleted(res.data.userAnswers ? res.data.userAnswers.length : 0);
        if (displayType === "company") {
          await axios({
            method: "get",
            url: `${process.env.REACT_APP_EVENT_SERVICE}/get-company-wop-graph-by-session?sessionId=${sessionId}&moduleId=${moduleId}`,
            // headers: {
            //   Authorization: `bearer ${loadAuthToken()}`,
            // },
          })
            .then((_res) => {
              if (
                res.data === "your token is not valid" ||
                res.data === "Your token is expired"
              ) {
                context.sendToLogin();
              }
              // setUsersAnswered(_res.data.userAnswers);
              setQuestionPercents(_res.data.questions);
              setQuestionsWithPercents(_res.data.questions);
            })
            .catch((err) => {
              if (
                err.error === "your token is not valid" ||
                err.error === "Your token is expired"
              ) {
                context.sendToLogin();
              }
              console.error(err);
            });
        } else if (displayType === "all") {
          await axios({
            method: "get",
            url: `${process.env.REACT_APP_EVENT_SERVICE}/get-wop-graph-for-everyone?moduleId=${moduleId}`,
            // headers: {
            //   Authorization: `bearer ${loadAuthToken()}`,
            // },
          })
            .then((_res) => {
              if (
                res.data === "your token is not valid" ||
                res.data === "Your token is expired"
              ) {
                context.sendToLogin();
              }
              // setUsersAnswered(_res.data.userAnswers);
              setQuestionPercents(_res.data.questions);
              setQuestionsWithPercents(_res.data.questions);
            })
            .catch((err) => {
              if (
                err.error === "your token is not valid" ||
                err.error === "Your token is expired"
              ) {
                context.sendToLogin();
              }
              console.error(err);
            });
        } else {
          // setUsersAnswered(res.data.userAnswers);
          setQuestionPercents(res.data.questions);
          setQuestionsWithPercents(res.data.questions);
        }
      })
      .catch((err) => {
        if (
          err.error === "your token is not valid" ||
          err.error === "Your token is expired"
        ) {
          context.sendToLogin();
        }
        console.error(err);
      });
  };

  useEffect(() => {
    getFinishedUsers();
    if (channel) {
      channel.bind("wop-answer", () => {
        getFinishedUsers();
      });
      return () => {
        // Clean up the subscription
        channel.unbind("wop-answer");
      };
    }
  }, [moduleId, channel, currentModuleId]);

  useEffect(() => {
    localStorage.setItem("wopModuleId", moduleId);
  }, [moduleId]);

  return (
    <div className={classes.outerDiv}>
      <div>
        <img src={Feet} className={classes.image} alt="walk of privilege" />
      </div>
      <Divider />
      <div className={classes.contentDiv}>
        <p className={classes.largeText}>
          <strong>{moduleTitle}</strong>
        </p>
        <br />
        <div>
          <p className={classes.text}>
            Please read each question carefully and respond appropriately.
          </p>
          <br />
          <br />
          <p className={classes.largeText}>COMPLETIONS</p>
          <strong>
            <p className={classes.largeText}>{String(completed)}</p>
          </strong>
          <br />
        </div>
      </div>
    </div>
  );
};

WalkOfPrivilege.propTypes = {
  moduleTitle: PropTypes.string,
  moduleId: PropTypes.number.isRequired,
  sessionId: PropTypes.number.isRequired,
  isDemo: PropTypes.bool,
  channel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default WalkOfPrivilege;
