/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { PulseLoader } from "react-spinners";
import { loadAuthToken } from "../../../../utils/auth";
import Button from "../../../../components/CustomButtons/Button";

import { words } from "../WordCloud/SampleWords";
import "./wordPopcorn.css";

const useStyles = makeStyles({
  image: {
    width: "150px",
    marginBottom: "5px",
  },
  listItem: {
    fontSize: "50px",
    marginTop: "25px",
  },
  list: {
    textAlign: "center",
  },
});

const WordPopcornScreen = ({
  // question,
  moduleId,
  sessionId,
  isDemo,
  // setViewingResults,
  // wordPopcornQuestion,
  newWord,
  // wordPopcornIcon,
  popcornData,
}) => {
  const [answers, setAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(null);

  const getWordPopcornAnswers = () => {
    // console.log("inside function");
    setIsLoading(!isLoading);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_EVENT_SERVICE}/word-popcorn-answer?moduleId=${moduleId}&sessionId=${sessionId}&isBadWord=false`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
    })
      .then((res) => {
        // console.log("popcorn screen", res.data.data);
        // console.log("session", sessionId, "module", moduleId);
        setAnswers(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    setAnswers((oldAnswers) => [...oldAnswers, newWord]);
  }, [newWord]);

  useEffect(() => {
    if (isDemo) {
      setAnswers(words);
    } else {
      getWordPopcornAnswers();
    }
  }, [moduleId, sessionId]);

  const classes = useStyles();
  return (
    <div style={{ marginTop: "10px" }}>
      <h2
        dangerouslySetInnerHTML={{ __html: popcornData.question }}
        style={{ margin: "20px 0px 0px", lineHeight: "50px" }}
      />
      <img
        src={popcornData.iconUrl}
        className={classes.image}
        alt="Word Popcorn"
      />
      {isLoading ? (
        <PulseLoader sizeUnit="px" size={15} color="#6CCA98" loading />
      ) : (
        <>
          <div className="listParent ">
            <div className="list">
              {answers.map((answer) => (
                <p className="word" key={answer.id}>
                  {answer.answer}
                </p>
              ))}
            </div>
          </div>
        </>
      )}

      {/* <Button
        width="100%"
        onClick={() => setViewingResults(false)}
        align="flex-end"
        style={{ right: '5vw', bottom: '7vh', position: 'absolute' }}
      >
        Back
      </Button> */}
    </div>
  );
};

WordPopcornScreen.propTypes = {
  sessionId: PropTypes.number.isRequired,
  moduleId: PropTypes.number.isRequired,
  isDemo: PropTypes.bool.isRequired,
  // setViewingResults: PropTypes.func,
  wordPopcornQuestion: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  newWord: PropTypes.object,
  wordPopcornIcon: PropTypes.string,
};

export default WordPopcornScreen;
